import { Container, Button,Form, Alert,Table, Modal} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import { FaFilePdf, FaReceipt, FaEnvelope,FaBars, FaUser } from 'react-icons/fa';
import React, {useMemo, useState } from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import useBackend, {CallAPI, Loading, SpinnerX} from '../services/useBackend';

export default function MyComponent() {
    const {loading, data} =  useBackend('/api/admin/customers');

    const [modalshow,  setModalshow] = useState(false);
    const initModal = () => { return setModalshow(!modalshow) } 
    const [modalText, setModalText] = useState("..");    

    const navigate = useNavigate();

    const operateLockClick = (event, rowData)  =>  {
        const customerguid = event.currentTarget.getAttribute("customerguid");
        //setModalText("........" ); 
        //setModalshow(!modalshow);
        navigate("/customer/"+rowData.original.Guid);
    }

    const addProductHandler = (event)  =>  {
        event.preventDefault();
        navigate("/product/add");
    }    
    
    const columns = useMemo(() => [
        { size: 20, accessorKey: 'SSN',   header: 'Personnummer', enableColumnActions: false },
        { size: 20, accessorKey: 'Name',  header: 'Namn' },
        { size: 20, accessorKey: 'eMail',  header: 'Email' },
        { size: 20, accessorKey: 'Banned',  header: 'Spärrad' },
        // { size: 20, header: 'Editera', columnDefType: 'display', enableColumnActions: false,            
        //     Cell: ({ row }) => ( <span style={{padding:5}} className='sub_' customerguid={row.original.Guid} onClick={operateLockClick} title="Info "><FaUser /></span> ),
        // },                   
      ]
    );       

    if (!loading) {
        return (
            <ContentWrapper>

                <Modal show={modalshow}>
                  <Modal.Header closeButton>
                      <Modal.Title>Modal.Title</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{modalText}</Modal.Body>
                  <Modal.Footer>
                      <Button variant="danger" onClick={initModal}>Close</Button>
                  </Modal.Footer>
                </Modal>

                <Container>
                    <Sida>                    
                        <MaterialReactTable  columns={columns} data={data.Customers}
                        muiTablePaginationProps={{ showFirstButton: false, showLastButton: false, labelRowsPerPage:"Rader"}}
                        renderTopToolbarCustomActions={() => (<Rubrik>Kunder</Rubrik>)}
                        initialState={{ pagination: { pageSize: 25 }, density: 'compact', columnVisibility: { Banned: false, eMail: false },  }}
                            muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                    operateLockClick(event, row);
                                  //console.info(event, row.original.Guid);
                                },
                                sx: {
                                  cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                },
                              })}                            
                        />                         
                    </Sida>
                </Container>                
            </ContentWrapper>
        );
    } else {
        return (<Loading />);        
    }
}