
import { Container, Button,Form,Alert,Modal } from 'react-bootstrap';
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";
import React, { useState, useEffect } from 'react';
import useBackend, {CallAPI, Loading, SpinnerX} from '../services/useBackend';
import Rules from './Rules';

const Signup = () => {

    const params = new URLSearchParams(
        window ? window.location.search : {}
    );

    const [resultText,    setResultText]    = useState(":)");
    const [resultVariant, setResultVariant] = useState("notset");
    const [resultVisible, setResultVisible] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);
    const [productValue,  setproductValue]  = useState(params.get('defaultproduct'));


    function handleSubmit(e) {
        e.preventDefault();

        setSpinnerHidden(false);
        setResultVisible(true);

        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())
    
        var PostData = {SystemID:"NS", FormData : formDataObj};  

        fetch('https://www.nookshop.se/backend/forms/signup', {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json'
        },
        body: JSON.stringify(PostData),
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException==false) {
            setResultVariant("success");      
            setResultText(data.resultText);        
            setSpinnerHidden(true);
            setResultVisible(true);
        } else {
            setResultVariant("warning");
            setResultText(data.resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }
     })
     .catch(error => {
        setResultVariant("warning");
        setResultText("Ett fel uppstod!" + JSON.stringify(PostData));
        setSpinnerHidden(true);
        setResultVisible(true);
      });
    }

    return (
        <ContentWrapper>




            <Container>
            <Sida>
                <Rubrik>Starta butik</Rubrik>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                        <Form.Control type="text" name ="contact" placeholder="Kontaktperson"  />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                        <Form.Control type="email" name="Email" placeholder="Email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                        <Form.Control type="text" name="company" placeholder="Företag" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail5">
                        <Form.Control type="text" name="orgnr" placeholder="Orgnr" />
                    </Form.Group>    

                    <Form.Group className="mb-3" controlId="formBasicEmail6">
                        <Form.Control type="text" name="street01" placeholder="Gatuadress" />
                    </Form.Group>    

                    <Form.Group className="mb-3" controlId="formBasicEmail7">
                        <Form.Control type="text" name="zip" placeholder="Postnr" />
                    </Form.Group>   

                    <Form.Group className="mb-3" controlId="formBasicEmail8">
                        <Form.Control type="text" name="city" placeholder="Ort" />
                    </Form.Group>         

                    <Form.Group className="mb-3"  controlId="formBasicEmail11">
                        <Form.Control as="select"  name="product_id" value={productValue}           onChange={e => {console.log("e.target.value", e.target.value); setproductValue(e.target.value);} }>
                            <option value="NS1">Kylskåp</option>
                            <option value="NS2">Butik bas</option>             
                            <option value="NS3">Butik standard</option>         
                            <option value="NS4">Butik pro</option>         
                        </Form.Control>
                    </Form.Group> 

                    <Form.Group className="mb-3"  controlId="formBasicEmail22">
                        <Form.Control type="password" name="password" placeholder="Lösenord" />
                    </Form.Group>                                                                                                                                 

                    <Form.Group className="mb-3"  controlId="formBasicEmail33">
                        <Form.Control type="password" name="password_validate" placeholder="Lösenord" />
                    </Form.Group>     

                    <Form.Group className="mb-3" controlId="formBasicEmail23">
                        <Form.Check name="contractok" size="sm" type="checkbox" id="2"  label="Jag har läst och förstått avtalet för vald tjänst." inline/><Rules></Rules> 
                    </Form.Group>                              

                    <Stycke hidden={spinnerHidden}>
                        <SpinnerX  />
                    </Stycke>                                

                    <Alert show={resultVisible} variant={resultVariant} Style="font-size: 20px;">{resultText}</Alert>
                    <Button className="QmeButton" variant="primary" type="submit" disabled={resultVariant=="success"? true : false}>Skapa butik</Button>
                </Form>     
            </Sida>
            </Container>
        </ContentWrapper>
    );
};

export default Signup;