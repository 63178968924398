import { Container, Button,Form, Alert,RadioGroup,FormControlLabel,Radio} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import useBackend, {CallAPI, Loading, SpinnerX} from '../services/useBackend';
import React, { useState } from 'react';

export default  function MyComponent() {
    
    const [loading, setLoadning] = useState(false);
    const [saving,        setSaving]        = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );  

    const handleClaimSubmit = async (e) =>{
        e.preventDefault();

        setSaving(true);
        setResultText("");
        setResultVisible(false);
        const ShopForm = document.getElementById('AddShopForm');
        const formData = new FormData(ShopForm);
        const formDataObj = Object.fromEntries(formData.entries());

        let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI('/api/admin/shop', 'POST', JSON.stringify(formDataObj));

        setResultVariant(resultVariant);   
        setResultText(resultText);
        setResultVisible(resultVisible);
     
        // setLoadning(true);

        setResultVisible(true);
        // Refresh();
        // setLoadning(false);
    }     

    return (
        <ContentWrapper>
            <Container>
                <Sida>
                <Rubrik>Lägg till butik</Rubrik>

                <Form onSubmit={handleClaimSubmit} id="AddShopForm">

                    <Form.Group className="mb-3" >
                            <Form.Label>Namn på butiken/kylskåpet</Form.Label>
                            <Form.Control type="text" name ="Name" placeholder="Ange namn på butiken/kylskåpet" />                            
                    </Form.Group>

                    <Form.Group className="mb-3" >
                            <Form.Label>ID på låsmodul/kylskåp</Form.Label>
                            <Form.Control type="text" name ="LockId" placeholder="" />                            
                    </Form.Group>                    

                    {/* <Form.Group className="mb-3" >
                            <Form.Check name="Product" size="sm" type="radio" id="1"  label="Kylskåp" defaultChecked/>
                            <Form.Check name="Product" size="sm" type="radio" id="2"  label="Fysisk butik"/>
                    </Form.Group> */}

                    <StyckeCompact>
                        Vid val av kylskåp kommer vi kontakta dig för att hitta lämpligt leveransalternativ. Vid val av fysisk butik kommer vi att skicka dig en wifi-modul som kopplas ihop med ditt elektriska dörrlås. Saknas elektriskt dörrlås kontakta din lokala låssmed. 
                    </StyckeCompact>  

                    <Form.Group className="mb-3"  hidden={!loading}>
                        <SpinnerX  />
                    </Form.Group>                                      
                        
                    <Form.Group className="mb-3"  hidden={!resultVisible}>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                    </Form.Group>                        

                    <Form.Group className="mb-3">

                        <Button className="QmeButton" variant="primary" type="submit">Skapa butiken</Button>
                    </Form.Group>                                
                </Form>                 
            </Sida>
            </Container>
        </ContentWrapper>
    );

}