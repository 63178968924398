import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";

import React, { useState ,useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import useBackend, {CallAPI, Loading, SpinnerX} from '../services/useBackend';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, AddTimezoneOffset} from '../services/misc';

var typingTimeout = null;
var shopGUID = null;





export default function ShopEdit() {
    const {ShopGUID} = useParams();
    shopGUID = ShopGUID;
    const {loading, data, resultSuccess, Refresh} =  useBackend('/api/admin/shop/'+ShopGUID);

	const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);

	function handleChange(event){
		clearTimeout(typingTimeout);
		typingTimeout = setTimeout(handleSave, 475);
	}

	async function RenewEntryCodeClicked(event){	
		event.preventDefault();
		await CallAPI('/api/admin/shop/'+shopGUID+'/renewentrycode',"PUT");	
		Refresh();
	}

	async function handleSave(){
		const ProductForm = document.getElementById('ProductForm');
		const formData = new FormData(ProductForm);
	
		const formDataObj = Object.fromEntries(formData.entries());
	
		formDataObj.Swish       = formData.has('Swish') ? true : false;
		formDataObj.Card        = formData.has('Card') ? true : false;
		formDataObj.TestPayment = formData.has('TestPayment') ? true : false;

		formDataObj.OpeningHoursUTCStart = ConcatLocalDateAndTime2ISO("1900-01-01",formDataObj.OpeningHoursUTCStart);
		formDataObj.OpeningHoursUTCEnd   = ConcatLocalDateAndTime2ISO("1900-01-01",formDataObj.OpeningHoursUTCEnd);

		await CallAPI('/api/admin/shop/'+shopGUID,"PATCH",JSON.stringify(formDataObj));
		Refresh();		
	}

    if (!loading) {
        return (
            <ContentWrapper>
				
                <Container>
                    <Sida>
						<Rubrik>Butiksinformation</Rubrik>

						<Form onSubmit={handleSave} id="ProductForm">

							<Form.Group className="mb-3" >
                                <Form.Label>Namn</Form.Label>
								<Form.Control type="text" onChange={handleChange} name="Name" placeholder="ButikID"  defaultValue={data.Shop.Name} />
							</Form.Group>

							<Form.Group className="mb-3" >
                                <Form.Label>Beskrivning</Form.Label>
								<Form.Control type="text" onChange={handleChange} name="Description" placeholder="Produktbeskrivning" defaultValue={data.Shop.Description} />
							</Form.Group>  

							<Form.Group className="mb-3" >
                                <Form.Label>Max tillåtna ofullständiga köp</Form.Label>
								<Form.Control type="text" onChange={handleChange} name="MaxAllowedUncompletedSessions" placeholder="MaxAllowedUncompletedSessions" defaultValue={data.Shop.MaxAllowedUncompletedSessions} />
							</Form.Group>  

							<Form.Group className="mb-3" >
                                <Form.Label>Minsta ålder för att använda butiken</Form.Label>
								<Form.Control type="text" onChange={handleChange} name="MinCustomerAge" placeholder="MinCustomerAge" defaultValue={data.Shop.MinCustomerAge} />
							</Form.Group>


							<Form.Group className="mb-3" >
                                <Form.Label>Öppettider</Form.Label>
								<div className="d-flex">
									<Form.Control type="time"  onChange={handleChange} name="OpeningHoursUTCStart" defaultValue={SplitoutLocalTimeFromISO(data.Shop.OpeningHoursUTCStart)} size='sm' step="60"/>&nbsp;till&nbsp;
									<Form.Control type="time"  onChange={handleChange} name="OpeningHoursUTCEnd"   defaultValue={SplitoutLocalTimeFromISO(data.Shop.OpeningHoursUTCEnd)}   size='sm' step="60"/>
								</div>
							</Form.Group>
													
							<Form.Group className="mb-3">
								<Form.Label>EntryCode</Form.Label>
								<div className="d-flex">
								{data.Shop.EntryCode!=null ?
									(<Form.Control type="text"  value={data.Shop.EntryCode.Code} disabled />) :(<Form.Control type="text"  disabled />)
								}&nbsp;
								<Button className="QmeButton" variant="primary" type="submit" onClick={RenewEntryCodeClicked}>Förnya</Button>
								</div>
							</Form.Group>

							<Form.Group className="mb-3" >
								<Form.Check 
									type="switch"
									name="Swish"
									id="Swish"
									label="Swish"
									defaultChecked={data.Shop.Swish}
									value={data.Shop.Swish}
									onChange={handleChange}
								/>
							</Form.Group>                               
							

							<Form.Group className="mb-3" >
								<Form.Check 
									type="switch"
									name="TestPayment"
									id="TestPayment"
									label="Testbetalning"
									defaultChecked={data.Shop.TestPayment}
									onChange={handleChange}
								/>
							</Form.Group>                                                                                                                                                                     
						</Form> 		

						<Form.Group className="mb-3"  hidden={!loading}>
							<SpinnerX  />
						</Form.Group>                                      
							
						<Form.Group className="mb-3"  hidden={!resultVisible}>
							<Alert show={resultVisible} variant={resultVariant} className="FormSpace">{resultText}</Alert> 
						</Form.Group> 

						<Rubrik>Kopplat lås</Rubrik>
						<StyckeCompact>
							{/* <>LockType:        {data.Shop.Lock.LockType}</><br />
							<>Online:          {String(data.Shop.Lock.Online)}</><br />
							<>LockState:       {data.Shop.Lock.LockStateId}</><br />
							<>Batteristatus:   {data.Shop.Lock.BatteryStatus}%</><br />
							<>SerialNumber:    {data.Shop.Lock.SerialNumber}</><br />
							<>FirmwareVersion: {data.Shop.Lock.FirmwareVersion}</> */}
						</StyckeCompact>
								
						<StyckeCompact>
							<>Camera:       {data.Shop.CameraURL}</>
						</StyckeCompact>						

                    </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (<Loading />);        
    }
}