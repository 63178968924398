import '../App.css';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router';
import {StyckeCompact} from "./ContentStyles";
import { Container, Button,Form,Alert,Modal } from 'react-bootstrap';


const Rules = () => {

    const [showAvtal,     setShowAvtal]     = useState(false);
    return (
        <div Style="display:inline;">
            <Modal show={showAvtal} onHide={()=> {setShowAvtal(!showAvtal)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Avtal nookshop</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li>Cloudsolution Nordic AB (Nookshop) äger tillhandahållen hårdvara om ej annat överenskoomits.</li>                        
                        <li>Fasta avgifter faktureras i förväg 3 månader åt gången.</li>
                        <li>Eventuella fraktkostnader debiteras kund.</li>
                        <li>Uppsägning av avtal ska göras innan påbörjan av ny 3 månadersperiod.</li>
                        <li>Kund förbinder sig att ta hand om tillhandahållen hårdvara på ett ändamålsenligt sätt, onormalt slitage kan komma att faktureras.</li>
                        <li>Kund ansvarar för uppkoppling till Internet via WiFi.</li>
                        <li>Tillhandahållen hårdvara ska returneras skyndsamt efter avslutad avtalsperiod. Ej returnerad faktureras.</li>                                                
                        
                        <li>Vid uppsägning innan eventuell bindningstids löpt ut löper fakturering på tills bindningstiden löpt ut.</li>
                        
                        <li>Cloudsolution Nordic AB tar inget ansvar för eventuell skada som drabbar tredje part.</li>
                        <li>Cloudsolution Nordic AB (Nookshop) förbehålls rätten att stänga ner tillhandahållna tjänster vid eventuella oegentligheter, tex utebliven betalning eller om verksamheten misstänks bryta mot svensk lag. Nedstängning kan även ske under tiden som frågan utreds.</li>
                        <li>Avtalet kan komma att överlåtas vid tex företagsfussion eller omorganisation.</li>
                        <li>Eventuell tvist löses i första hand mellan aktuella parter eller i andra hand vid Kristianstads tingsrätt.</li>
                    </ul>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className='QmeButton' onClick={()=> {setShowAvtal(!showAvtal)}}>Stäng</Button>
                </Modal.Footer>
            </Modal>

            &nbsp;<a href='' onClick={(e) => {e.preventDefault(); setShowAvtal(!showAvtal); return false;}}>Visa avtal</a>
            {/* <Button variant="link" onClick={() => {setShowAvtal(!showAvtal)}}>Visa avtal</Button> */}
    </div>
  )
};
export default Rules;