import { Container, Alert,Table, Modal, Button} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper} from "./ContentStyles";

import { FaFilePdf, FaReceipt, FaCamera, FaInfo, FaUser, FaShoppingBasket, FaStore} from 'react-icons/fa';
import React, {useMemo, useState, useEffect } from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import useBackend, {CallAPI, Loading, SpinnerX} from '../services/useBackend';
import ReactPlayer from 'react-player';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions} from '../services/misc';

export default function MyComponent() {
    const [modalshow,  setModalshow] = useState(false);
    const initModal = () => { return setModalshow(!modalshow) } 

    const [modalvideoshow,  setModalVideoshow] = useState(false);
    const initVideoModal = () => { return setModalVideoshow(!modalshow) }     

    const [selectedOrderData, setSelectedOrderData] = useState({Customer: {}, Shop : {}});  

    const navigate = useNavigate();

    // const [searchParams] = useSearchParams();
    // var pageIndex = parseInt(searchParams.get('pageindex'),10) || 0;
    // var pageSize  = parseInt(searchParams.get('pagesize'),10)  || 25;

    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });
    // const [backendURL, setBackendURL] = useState('/api/admin/orders');

    const {loading, data, Refresh} =  useBackend('/api/admin/orders2');

    //useEffect(() => { navigate('?pageindex='+pagination.pageIndex+'&pagesize='+pagination.pageSize) }, [pagination]);

    const orderRowClick = (event, rowData)  =>  {
        //const OrderGUID = event.currentTarget.getAttribute("orderguid");
        // navigate("/orders/"+rowData.original.Guid);
        //console.log(rowData.original);
        setSelectedOrderData(rowData.original)
        setModalshow(!modalshow);
    }
    
    const orderDetailClick = (event)  =>  {
        const OrderGUID = event.currentTarget.getAttribute("orderguid");
        event.preventDefault();
        navigate("/orders/"+OrderGUID);
    }

    const customerDetailClick = (event)  =>  {
        const CustomerGUID = event.currentTarget.getAttribute("customerguid");
        //console.log(CustomerGUID);
        //event.preventDefault();
        navigate("/customer/"+CustomerGUID);
    }
    
    const shopDetailClick = (event)  =>  {
        const ShopGUID = event.currentTarget.getAttribute("shopguid");
        navigate("/shop/"+ShopGUID);
    }       
    
    const setOrderStateClick = async (event)  =>  {
        const NewState = event.currentTarget.getAttribute("newstate");
        //(selectedOrderData.Guid)
		await CallAPI('/api/admin/Orders/'+selectedOrderData.Guid,"PATCH","{\"NewOrderState\":\"10\"}");
        Refresh(pagination.pageIndex, pagination.pageSize);
    }

    const showVideoClick = (event)  =>  {
        const OrderGUID = event.currentTarget.getAttribute("orderguid");
        console.log(OrderGUID);
        setModalVideoshow(!modalvideoshow);
        //event.preventDefault();
        //navigate("/customer/"+CustomerGUID);
    }      
    
    const columns = useMemo(() => [
        { size: 20, accessorKey: 'SerialNumber',   header: '#' },

        { size: 20, header: 'Tid'           , enableColumnActions: false,
            Cell: ({ row }) => ( <span>{DateTimeToLocal(row.original.Created)}</span> ),
        }, 
    

        { size: 20, header: 'Butik'           , enableColumnActions: true,
            Cell: ({ row }) => ( <span>{row.original.Shop.Name}/{row.original.Shop.Description}</span> ),
        },         

        { size: 20, header: 'Kund'           , enableColumnActions: false,
            Cell: ({ row }) => ( <span>{row.original.Customer.SSN}/{row.original.Customer.Name}</span> ),
        }, 
        { size: 10, accessorKey: 'StateText',  header: 'Status' },
        // { size: 20, header: 'Editera', columnDefType: 'display', enableColumnActions: false,            
        //     Cell: ({ row }) => ( <span style={{padding:5}} className='sub_' orderguid={row.original.Guid} onClick={orderClick} title="Info "><FaReceipt /></span> ),
        // },                   
      ]
    );
    
    const initialState = 
    { 
        density: 'compact',
        columnVisibility: { Company: false, Kund:false, 'SerialNumber':false },
        pagination : { pageIndex: 0, pageSize: 25 }
    };

    useEffect(() => {
        //do something when the pagination state changes
        Refresh(pagination.pageIndex, pagination.pageSize);

        console.log('Page: ' + pagination.pageIndex);
        console.log('pageSize: ' + pagination.pageSize);
        
      }, [pagination.pageIndex, pagination.pageSize]);
    
    const state = { pagination };      

    if (!loading) {
        return (
            <ContentWrapper>

                <Modal show={modalvideoshow} onHide={initVideoModal}>
                  <Modal.Header closeButton>
                      <Modal.Title>{selectedOrderData.Shop.Description} ({selectedOrderData.SerialNumber})</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>                
                    <ReactPlayer className='react-player fixed-bottom_' width="100%" controls={true} url= {"https://www.nookshop.se/static/movie/"+selectedOrderData.Guid+".mp4"} />
                  
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={initVideoModal}>Close</Button>
                  </Modal.Footer>
                </Modal>                

                <Modal show={modalshow} onHide={initModal}>
                  <Modal.Header closeButton>
                      <Modal.Title>{selectedOrderData.Shop.Description} ({selectedOrderData.SerialNumber})</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <Table  responsive="sm">
                        <tbody>
                            <tr><td>Skapad</td><td>{DateTimeToLocal(selectedOrderData.Created)}</td></tr>
                            <tr><td>Ordernr</td><td>{selectedOrderData.SerialNumber}</td></tr>
                            <tr><td>Butik</td><td>{selectedOrderData.Shop.Name}/{selectedOrderData.Shop.Description}</td></tr>
                            <tr><td>Status</td><td>{selectedOrderData.StateText}</td></tr>
                            <tr><td>Kund</td><td>{selectedOrderData.Customer.Name}</td></tr>
                            <tr><td></td><td>{selectedOrderData.Customer.SSN}</td></tr>
                        </tbody>
                    </Table>                    

                  
                  </Modal.Body>
                  <Modal.Footer>
                  <Button className="QmeButton" variant="primary" type="button" orderguid={selectedOrderData.Guid} onClick={orderDetailClick}><FaShoppingBasket/></Button>

                  <Button className="QmeButton" variant="primary" type="button" customerguid={selectedOrderData.Customer.Guid} onClick={customerDetailClick}><FaUser/></Button>

                  <Button className="QmeButton" variant="primary" type="button" shopguid={selectedOrderData.Shop.Guid} onClick={shopDetailClick}><FaStore/></Button>

                  <Button className={(selectedOrderData.HasVideo ? ("QmeButton"):("QmeButtonDisabled"))}  variant="primary" type="button" orderguid={selectedOrderData.Guid} onClick={showVideoClick} disabled={!selectedOrderData.HasVideo}><FaCamera/></Button>

                  {selectedOrderData.State!=5 && ( <Button className="QmeButton" variant="primary" type="button" orderguid={selectedOrderData.Guid} onClick={setOrderStateClick} newstate="10">Makulera</Button> )}

                  {/* <Button className="QmeButton_" variant="secondary" type="button" shopguid={selectedShopData.Guid} onClick={editShopClick}><FaCog/></Button> &nbsp;&nbsp;   
                    
                    <Button className="QmeButton" variant="primary" type="button" shopguid={selectedShopData.Guid} onClick={unLockShop}>Lås upp</Button>&nbsp;&nbsp; */}
                    <Button variant="danger" onClick={initModal}>Close</Button>
                  </Modal.Footer>
                </Modal>

                <Container>
                    <Sida>                    
                        <MaterialReactTable 
                            enableColumnActions= {false}
                            columns={columns}
                            data={data.Orders}
                            manualPagination= {true}
                            paginationDisplayMode='pages'
                            rowCount= {data.totalNumOfRows}
                            onPaginationChange={setPagination} 
                            state={state}
                            muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                            muiTablePaginationProps={{ showFirstButton: true, showLastButton: true ,labelRowsPerPage:"Rader", rowsPerPageOptions: [25, 50, 100]}}
                            initialState={initialState}
                            renderTopToolbarCustomActions={() => (<Rubrik>Besök</Rubrik>)} 

                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                    orderRowClick(event, row);
                                  //console.info(event, row.original.Guid);
                                },
                                sx: {
                                  cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                },
                                elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} 
                              })}
                        />                    
                    </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (<Loading />);         
    }
}