import Container from 'react-bootstrap/Container';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import Accordion from 'react-bootstrap/Accordion';

function Project() {
  return (

        <ContentWrapper>
        <Container>
            <Sida>

<Rubrik>Projekt</Rubrik>

              <Accordion defaultActiveKey="2">

              <Accordion.Item eventKey="1">
                  <Accordion.Header>ToDo Appen version: 120, build:123</Accordion.Header>
                  <Accordion.Body>
                  <ul>                                    
                    <li>Ta bort vara</li>
                  </ul>  
                  </Accordion.Body>
                </Accordion.Item>                

                <Accordion.Item eventKey="2">
                  <Accordion.Header>ToDo Webb/Backend</Accordion.Header>
                  <Accordion.Body>
                  <ul>                                    
                    <li>3 erbjudande (Basic, Standard, Advanced)</li>
                    <li>Populera FAQ</li>
                    <li>Backendkontroll mot swish</li>
                    <li>Lägg till butik</li>
                    <li>BankID Hemsida</li>        
                    <li>Säkra API</li>
                  </ul>  
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>Workshop</Accordion.Header>
                  <Accordion.Body>
                    <ul>                  
                      <li>Kylskåp/wending (Basic med lås)</li>
                      <li>Microbutik med honung (Basic)</li>
                      <li>Butik/Kiosk (Standard med lås & kamera)</li>     
                      <li>Spin Of Hope</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>Varsego</Accordion.Header>
                  <Accordion.Body>
                    <ul>                  
                      <li>Ordna fram Varsegobrandad kyl</li>
                      <li>Prata med Lasarettet i Simrishamn</li>
                      <li>Testa låset på en frys</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>Sallad</Accordion.Header>
                  <Accordion.Body>
                    <ul>                  
                      <li>Tonfisk,  Körsbärstomater, ...........</li>
                      <li>Rostbiff, Körsbärstomater, Bönor, ........... </li>
                      <li>Kyckling, Körsbärstomater, Majs, Gurka, ...........</li>
                      <li>Ingridienser, Bönor, Körsbärstomater, Majs, Gurka</li>
                      <li>Pestopasta, ...........</li>
                      <li>Chili Con/Sin Carne, ...........</li>
                    </ul> 
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>Info</Accordion.Header>
                  <Accordion.Body>
                    <ul>                  
                      <li>WiFi nookshop/nE87c2MpUA</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header>Andra aktörer</Accordion.Header>
                  <Accordion.Body>
                    <ul>                  
                      <li>https://neverclosed.se</li>
                      <li>https://www.24-sju.se</li>
                      <li>https://www.jobbs.se</li>
                      <li>https://honestbox.se</li>
                      <li>https://www.storekey.com</li>
                      <li>https://www.swish.nu</li>
                      <li>https://lifvs.com (storekey) Kursat</li>
                      <li>Automat (TruePOS/Kassacentralen)</li>     
                    </ul>  
                  </Accordion.Body>
                </Accordion.Item> 

                <Accordion.Item eventKey="8">
                  <Accordion.Header>To Do</Accordion.Header>
                  <Accordion.Body>
                    <ul>                                    
                      <li>Beställa dörr 9x20</li>
                      <li>Klistermärke</li>
                      <li>Rullande ButikID, SenseCAP Indicator, Wio Terminal, m5stack</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                  <Accordion.Header>Leads</Accordion.Header>
                  <Accordion.Body>
                  <ul>                  
                    <li>Ottoglass</li>
                    <li>Flygplatsen</li>
                    <li>Grillkol</li>
                    <li>Landön</li>

                    <li>Simrishamn lasarett</li>                  
                    <li>Campingmässa</li>
                    <li>Gårdsbutiker</li>            
                    <li>Varsego, besöka Ystad</li>
                    <li>9-onde hålet</li>
                    <li>Workshop</li>
                    <li>Idrottsföreningar</li>                  
                    <li>Fredagsfrukost</li>
                    <li>Äspet</li>
                  </ul>                    
                  </Accordion.Body>
                </Accordion.Item> 

                <Accordion.Item eventKey="10">
                  <Accordion.Header>ToDo Appen version: 121, build:xxx</Accordion.Header>
                  <Accordion.Body>
                    <ul>                                    
                      <li>Bättre grafik IOS</li>
                      <li>Testa olika inställningar på Scannern</li>
                      <li>Kunna inaktivera scannern</li>                  
                      <li>Någon form av kortbetalning</li>
                      <li>Funktion för rullande kod</li>
                    </ul>                      
                  </Accordion.Body>
                </Accordion.Item>                                                                                              

              </Accordion>                
            </Sida>
        </Container>
    </ContentWrapper>        
  );
}

export default Project;