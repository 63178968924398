import React, {useState} from "react";
import Container, {Dropdown, Alert, Button} from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import './Menu.css';

import useBackend, {XLogOff, IsLoggedIn} from '../services/useBackend';
import { FaSms, FaSignOutAlt, FaKey, FaUser, FaCog } from 'react-icons/fa';

//import 'bootstrap/dist/css/bootstrap.min.css';

const history = createBrowserHistory();

const Menu = () => {

  var {loggedIn, isAdmin, isSuperUser, userName } = IsLoggedIn();

  // window.addEventListener('scroll', testfn)

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  
  return (

    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-dark ">

      <a className="navbar-brand" href="/">
        <img alt="" src="/images/logo.png" height="40px" className="d-inline-block align-top"/>{' '}
</a>
        <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
          <span className="navbar-toggler-icon"></span>
        </button>
                      
        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
          <ul className="navbar-nav">

              {loggedIn ? ( 
                <>

                  <a style={{color:"white"}} className="nav-item nav-link" href={process.env.PUBLIC_URL+"/products"}>Produkter</a>        
                  <a style={{color:"white"}} className="nav-item nav-link" href={process.env.PUBLIC_URL+"/shops"}>Butiker</a>        
                  <a style={{color:"white"}} className="nav-item nav-link" href={process.env.PUBLIC_URL+"/customers"}>Kunder</a>        
                  <a style={{color:"white"}} className="nav-item nav-link" href={process.env.PUBLIC_URL+"/orders"}>Besök</a>        
                  <a style={{color:"white"}} className="nav-item nav-link" href={process.env.PUBLIC_URL+"/sellreport"}>Försäljning</a>        
                  {isSuperUser && ( <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/Project"}>Project</a> )}
                  {isSuperUser && ( <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/Instances"}>Instanser</a> )}
                  
                </>
                ) :(
                <>
                  <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/"}>Hem</a>
                  <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/contact"}>Kontakt</a>                  
                  <a style={{color:"white"}} className="nav-item nav-link" href={process.env.PUBLIC_URL+"/signup?defaultproduct=C20"}>Starta butik</a>
                </>                  
              )}

              {/* <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/Project"}>Project</a>                
              <a style={{color:"white"}} className="nav-item nav-link active" href={process.env.PUBLIC_URL+"/Example"}>Example</a>                 */}

          </ul>
          <ul className="navbar-nav ms-auto">
            {loggedIn && isNavCollapsed && (
              <>
                <div className="dropdown nav-link">
                    <a style={{color:"white"}} href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{userName}</a>
                    <div className="dropdown-menu dropdown">                            
                        <a href={process.env.PUBLIC_URL+"/instance/invoices"} className="dropdown-item"><FaSms/>&nbsp;Mina Fakturor</a>
                        <a href={process.env.PUBLIC_URL+"/mypage"} className="dropdown-item"><FaCog/>&nbsp;Min Profil</a>
                        <a href={process.env.PUBLIC_URL+"/instance/subscriptions"} className="dropdown-item"><FaCog/>&nbsp;Mina Tjänster</a>
                        <a href={process.env.PUBLIC_URL+"/password/change"} className="dropdown-item"><FaKey/>&nbsp;Byt lösenord</a>
                        <a className="dropdown-item" onClick={XLogOff}><FaSignOutAlt/>&nbsp;Logga ut</a>
                    </div>
                </div>                    
              </>)
            }

            {loggedIn && !isNavCollapsed && (
              <>      
                <hr style={{color:"white", padding:"2px"}}></hr>
                <div style={{color:"white", padding:"2px"}}>{userName}</div>
                <div>
                  <a style={{color:"white", marginRight:"12px"}} className="nav-item nav-link_ active"  onClick={XLogOff}><FaSignOutAlt/>&nbsp;Logga ut</a>
                  
                  <a style={{color:"white", marginRight:"12px"}} className="nav-item nav-link_ active" href={process.env.PUBLIC_URL+"/password/change"} ><FaKey/>&nbsp;Byt lösenord</a>
                  
                  <a style={{color:"white", marginRight:"12px"}} className="nav-item nav-link_ active" href={process.env.PUBLIC_URL+"/mypage"} ><FaCog/>&nbsp;Min Profil</a>
                </div>
              </>) 
            }                    

            {!loggedIn && (<><Button className="CloudLoginButton" variant="primary" href="/login">Logga in</Button></>)}  

            
            
          </ul>      
          
        </div>
      </nav>    
      {isNavCollapsed && (<><div className="Top"><p className="TopLine" id="logo">Bedriv din verksamhet obemannat 24/7</p></div></>)}
    </div>

  )
};

export default Menu;