import '../App.css';

import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form} from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';

import $ from "jquery";
import Faq from "./Faq";
import React, { useState, Component } from 'react';
import axios from "axios";
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";



const shoot = () => {
  alert();
}

const QR = () => {

   return(
        <ContentWrapper>
    
        <Container>

        <Sida id="Start"><br></br>
          <Rubrik>Hur fungerar det?</Rubrik>
          <Stycke>
            <ul>
              <li>
                  Ladda ner nookshop till din telefon<br></br>
                  <a className="links"  href="https://apps.apple.com/us/app/nookshop/id6443705977"><img height="40px" src="https://www.nookshop.se/images/AppStore.png" /></a>&nbsp;
                  <a className="links"  href="https://play.google.com/store/apps/details?id=cloudsolution.nookshop"><img height="40px" src="https://www.nookshop.se/images/GooglePlay.png" /></a><br></br><br></br> 
              </li>
              <li>Lås upp butiken med BankID</li>
              <li>Scanna dina varor</li>
              <li>Betala med Swish</li>
              <li>Stäng dörren efter dig</li>
            </ul>
          </Stycke>      
        </Sida>    

        <Sida id="faq">
          <Faq />
          </Sida>            
        </Container>

        </ContentWrapper>
      );
   }

export default QR;