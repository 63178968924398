import Container from 'react-bootstrap/Container';
import { Collapse, Popover, Toast, Tooltip, Alert, Modal, Dropdown, Card, Table, Button,Form } from "react-bootstrap";

import { FaFilePdf, FaBars, FaCommentDots, FaEdit, FaTrashAlt } from 'react-icons/fa';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import React, {useMemo, useState ,useEffect, useReducer, fetchData} from 'react';
import MaterialReactTable from 'material-react-table';
import useBackend, {DataFromAPI, Loading, CallAPI, SpinnerX, Check} from '../../services/useBackend';

import {Productgroups} from './Productgroups'
  
const muiTablePaperProps      = {elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} };
const muiTablePaginationProps = {showFirstButton: false, showLastButton: false, labelRowsPerPage:"Rader"}
const initialState            = {density: 'compact' }; 

function Register(props) {
   const {loading:registerloading, data:registerdata, Refresh} = useBackend(process.env.PUBLIC_URL+'/api/admin/register');
   
   if (!registerloading) {
      return (
            <ContentWrapper>
               <Container>
                  <Sida>                  
                     <Productgroups          Rubrik="Produktgrupper" RefreshFunction={Refresh} muiTablePaperProps={muiTablePaperProps} muiTablePaginationProps={muiTablePaginationProps} initialState={initialState} data={registerdata.productgroups}/><br/>               
                  </Sida>
               </Container>      
            </ContentWrapper>    
      );
   }
}

export default Register;