import React, { useMemo, useState, useRef  ,useEffect, useReducer, fetchData} from 'react';
import { Alert, Button, Container, Card, Table, Modal, Accordion,Form, FormLabel, Col, Row } from 'react-bootstrap';

import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import useBackend, {XLogOff, IsLoggedIn, Loading, SpinnerX, CallDownload} from '../services/useBackend';
import MaterialReactTable from 'material-react-table';
import {getOptions} from '../services/misc';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FaFilePdf, FaReceipt, FaEnvelope,FaBars, FaUser, FaFileDownload, FaDownload } from 'react-icons/fa';
//import { mkConfig, generateCsv, download } from 'export-to-csv';

import download from 'downloadjs'
import { Link } from 'react-router-dom';

function SellReport() {

  const {loading, data, Refresh, Seek} =  useBackend('/api/report/sell');

  return (
        <ContentWrapper>
        <Container>
            <Sida>
                <Rubrik>Rapporter</Rubrik>

                <div className='d-flex'></div>

                {loading && <SpinnerX />}

                {!loading &&
                    (      
                    <>
                        <a className="text-decoration-none" style={{color:'#000', fontSize:'20px'}} href="/SellToInvoiceReport">Fakturarapport</a><br/>
                        <a className="text-decoration-none" style={{color:'#000', fontSize:'20px'}} href="/sellreport">Försäljningsrapport</a><br/>
                    </>                                                                  
                    )
                }
               
            </Sida>
        </Container>
    </ContentWrapper>        
  );
}

export default SellReport;