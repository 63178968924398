import '../App.css';

import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form, Alert} from 'react-bootstrap';

import $ from "jquery";
import Faq from "./Faq";
import React, { useState, Component } from 'react';
import axios from "axios";
import {Sida,Rubrik,Stycke,ContentWrapper,Rubrik_} from "./ContentStyles";
import Prices from "./Prices";
import CountDown from "./CountDown";
import ReactPlayer from 'react-player';
import Album from "./Album";
import Rules from './Rules';


const shoot = () => {
  alert();
}

const Home = () => {

   return(
        <ContentWrapper>
        <Container>
        <Sida>

          <Rubrik>Vad är nookshop?</Rubrik>
          <Stycke>
            Hörnbutik som det skulle kunna heta på svenska om det inte låtit coolare på engelska är ett system för obemannade butiker. Kunden låser upp butiken genom att legitimera sig med BankID och betalar sina varor med Swish, så enkelt är det. Vår ambition är att det ska vara nästan lika enkelt att öppna en butik.<br />
          </Stycke>

          <div Style="">
            <div Style="float:left;">
              <Rubrik>Hur handlar jag i en nook?</Rubrik>
              <div>
                <div>
                  <ol>
                    <li>Ladda ner vår app till din telefon<br></br>
                      <a className="links"  href="https://apps.apple.com/se/app/nookshop/id6443705977"><img height="40px" src="https://www.nookshop.se/images/AppStore.png" /></a>&nbsp;
                      <a className="links"  href="https://play.google.com/store/apps/details?id=cloudsolution.nookshop"><img height="40px" src="https://www.nookshop.se/images/GooglePlay.png" /></a><br /><br />
                    </li>
                    <li>Ange butiksid i appen</li>
                    <li>Lås upp butiken med BankID</li>
                    <li>Scanna dina varor</li>
                    <li>Betala dina varor med Swish</li>
                    <li>Stäng dörren efter dig så att ingen obehörig kommer in i butiken</li>
                  </ol>
                </div>
              </div>

              <Rubrik>Exempel på verksamheter</Rubrik>

              <ul>
                <li>Gårdsbutiker</li>
                <li>Kioskverksamhet</li>
                <li>Hotell/Vandrarhem</li>
                <li>Livsmedelsbutik</li>
                <li>Golfbanor</li>
                <li>Arbetsplatser</li>
                <li>Idrottshallar</li>
                <li>Föreningar</li>
                <li>Gym</li>
              </ul>
            </div>

            <div className='' Style="float: right;height: auto; max-width:420px;">
                  <ReactPlayer url= 'handlaiennook.mp4' controls={true} playing={true} muted={true} loop={true} width='100%' height='100%' />
                </div>
            
              <div Style="clear:both;"></div>
          </div>    
    
          <Rubrik>Abonnemang</Rubrik>
          <Prices />

          <Rubrik>Bilder</Rubrik>
          <Album />
          <Faq />
          
        </Sida>    
        </Container>

        </ContentWrapper>
      );
   }

export default Home;