import '../App.css';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router';
import {StyckeCompact} from "./ContentStyles";
import { Container, Button,Form,Alert,Modal } from 'react-bootstrap';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const photos = [
    { href:"", src: "/images/album/DSC05855.jpg", width: 600, height: 900 },
    { href:"", src: "/images/album/DSC05799.jpg", width: 900, height: 900 },
    { href:"", src: "/images/album/DSC05834.jpg", width: 1350, height: 900 },
    { href:"", src: "/images/album/DSC05669.jpg", width: 600, height: 900 },
    
    
    { href:"", src: "/images/album/DSC05763.jpg", width: 1350, height: 900 },
    { href:"", src: "/images/album/DSC05674.jpg", width: 600, height: 900 },
    { href:"", src: "/images/album/DSC05885.jpg", width: 1350, height: 900 },    

    { href:"", src: "/images/album/lager.jpg", width: 1920, height: 900 },
    { href:"", src: "/images/album/peppar.jpg", width: 675, height: 900 },
    { href:"", src: "/images/album/IMG27.jpg", width: 1920, height: 900 },
    { href:"", src: "/images/album/IMG28.jpg", width: 1920, height: 900 },                   
  ];

const Album = () => {
    const [showAvtal,     setShowAvtal]     = useState(false);
    const [index, setIndex] = useState(-1);
    return (
        <div>


            <PhotoAlbum layout="rows" photos={photos} onClick={({ index }) => setIndex(index)} />

            <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />            

        </div>
    )
};
export default Album;