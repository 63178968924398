import React, { useMemo, useState, useRef  ,useEffect, useReducer, fetchData} from 'react';
import { Alert, Button, Container, Card, Table, Modal, Accordion,Form, FormLabel, Col, Row } from 'react-bootstrap';

import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import useBackend, {XLogOff, IsLoggedIn, Loading, SpinnerX, CallDownload} from '../services/useBackend';
import MaterialReactTable from 'material-react-table';
import {getOptions} from '../services/misc';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FaFilePdf, FaReceipt, FaEnvelope,FaBars, FaUser, FaFileDownload, FaDownload } from 'react-icons/fa';
//import { mkConfig, generateCsv, download } from 'export-to-csv';

import download from 'downloadjs'
import { Link } from 'react-router-dom';

function SellReport() {

  const {loading, data, Refresh, Seek} =  useBackend('/api/report/sell');
  
  var today = new Date(Date.now());
  var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [startDate, setStartDate] = useState(firstDayOfMonth.toLocaleDateString());
  
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
  const [endDate, setEndDate] = useState(lastDayOfMonth.toLocaleDateString());

  const [hideResult, setHideResult] = useState(false);
  
  const [shopFilter, setShopFilter] = useState(0);

  const columns = useMemo(() => [
    { size: 20, accessorKey: 'Name',   header: 'Namn' },       
    { size: 10, accessorKey: 'Ean',  header: 'EAN' },                 
    { size: 10, accessorKey: 'Qty',  header: 'Antal' },                 
  ]);

  async function handleExportData(SoldProducts) {        
    const csv = await CallDownload("/api/report/sell?startdate="+startDate+"&enddate="+endDate+"&ShopId=0&Format=xls");
    console.log("........");
    console.log(csv);
    //download(csv);
    download(csv, "report.csv", 'text/csv')
  };  

  useEffect(() => {
    if (startDate=="" || endDate=="" ) { setHideResult(true);} else {setHideResult(false);}
    if (Date.parse(endDate)<Date.parse(startDate) ) { setHideResult(true);}

     Seek("?startdate="+startDate+"&enddate="+endDate+"&ShopId="+shopFilter);
  }, [startDate, endDate, shopFilter]);

  return (
        <ContentWrapper>
        <Container>
            <Sida>
                <Rubrik>Försäljningsrapport</Rubrik>

                <div className='d-flex'></div>

                <Row>
                    <Col>Startdatum</Col>
                    <Col>Slutdatum</Col>
                    <Col>Butik</Col>
                    <Col></Col>
                </Row>

                <Row>
                    <Col><Form.Control type="date" name="Name" placeholder="Startdatum" value={startDate} onChange={async (e) => {await setStartDate(e.target.value); }} /></Col>
                    <Col><Form.Control type="date" name="Name_" placeholder="Slutdatum" value={endDate}   onChange={async (e) => {setEndDate(e.target.value); }} /></Col>
                    <Col>
                        <Form.Select name="ShopId" m onChange={async (e) => {setShopFilter(e.target.value); }} >
                            <option key="0" value="0">Alla</option>                                           
                            {!loading && getOptions(data.Shops)}
                        </Form.Select>                    
                    </Col>
                    {/* <Col><Button variant='secondary' onClick={() => {Seek("?startdate="+startDate+"&enddate="+endDate+"&ShopId="+shopFilter)}}>Sök/Uppdatera</Button> </Col> */}
                </Row>

                {loading && <SpinnerX />}

                {hideResult && <><br></br><Alert variant="danger">OBS ovanstående datumkombination är ogiltlig!</Alert> </>}

                {!loading &&
                    (      
                    <>              
                        <br></br>
                        <Rubrik>Belopp</Rubrik>

                        <Table responsive="sm">

                            <tbody>
                                <tr>
                                    <td style={{textAlign:'left'}}>Försäljning exkl.</td>
                                    <td style={{textAlign:'right'}}>{data.AmountEx}:-</td>
                                    <td></td>
                                </tr>

                                {data.AmountVAT.map((item) => {
                                    return <tr key={item.Vat}>
                                    <td style={{textAlign:'left'}}>Moms: {item.Vat}%</td>
                                    <td style={{textAlign:'right'}}>{item.Amount}:-</td>

                            </tr>
                                })}
                                  <tr>
                                    <td style={{textAlign:'left'}}><b>Totalt</b></td>
                                    <td style={{textAlign:'right'}}><b>{data.AmountIncl}:-</b></td>
                                    <td></td>
                                </tr>                                
                            </tbody>
                        </Table>  

                        <MaterialReactTable 
                            enableColumnActions= {false}
                            columns={columns}
                            data={data.SoldProducts}
                            manualPagination= {true}
                            enablePagination={false}
                            enableBottomToolbar={false}

                            muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                            muiTablePaginationProps={{ showFirstButton: false, showLastButton: false ,labelRowsPerPage:"Rader", rowsPerPageOptions: [1000]}}
                            
                            renderTopToolbarCustomActions= {() => (
                              <div style={{width:"100%"}}>
                                <div style={{float:"left"}}>
                                <Rubrik>Sålda produkter</Rubrik>
                                </div>

                                <div style={{float:"right"}}>
                                  <Button
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    data={data.SoldProducts}
                                    onClick={() => handleExportData(data.SoldProducts)}
                                    
                                    variant="outlined"
                                    title='Exportera CSV'
                                  >
                                    <FaDownload color="gray" />
                                  </Button>
                                </div>                             

                                </div>
                            )}
                                                          
                        />                                                             
                    </>                                          
                        
                    )
                }
               
            </Sida>
        </Container>
    </ContentWrapper>        
  );
}

export default SellReport;