import Container from 'react-bootstrap/Container';
import { Collapse, Popover, Toast, Tooltip, Alert, Modal, Dropdown, Card, Table, Button,Form } from "react-bootstrap";

import { FaFilePdf, FaBars, FaCommentDots, FaEdit, FaTrashAlt } from 'react-icons/fa';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import React, {useMemo, useState ,useEffect, useReducer, fetchData} from 'react';
import MaterialReactTable from 'material-react-table';
import useBackend, {DataFromAPI, Loading, CallAPI, SpinnerX, Check} from '../../services/useBackend';

export function Productgroups(props) {
    const state = { };
    const [object2Edit,  setObject2Edit]       = useState(null);
    const [editShow, setEditShow]      = useState(false);
    const [addShow, setAddShow]      = useState(false);

    const columns = useMemo(() => [
          { size: 40, accessorKey: 'Id',    header: 'Id', enableColumnActions: false },
          { size: 40, accessorKey: 'Name',  header: 'Namn' },
          { size: 20, header: 'Förvald',
          Cell: ({ row }) => (<Check checked={row.original.Default}/> ),
        }, 
       ]
    );

    const editOrganisationHandler = (event, rowData)  =>  {
       setObject2Edit(rowData.original);
       setEditShow(!editShow);
    }

    const editSaveHandler = async ()  =>  {
       const EventForm                 = document.getElementById('organisationForm');
       const formData                  = new FormData(EventForm);
       const formDataObj               = Object.fromEntries(formData.entries());
       let [resultVariant, resultText] = await CallAPI(process.env.PUBLIC_URL+'/api/register/productgroups/'+object2Edit.id, 'PATCH', JSON.stringify({ name : formDataObj.name }));
       setEditShow(!editShow);
       props.RefreshFunction();
    }

    const editRemoveHandler = async ()  =>  {
      setEditShow(!editShow);
      props.RefreshFunction();
   }

   const addHandler = async ()  =>  {
      setEditShow(!editShow);
      props.RefreshFunction();
   }      
 
    return (
       <>
       <Modal show={editShow} onHide={()=>setEditShow(!editShow)}>
          <Modal.Header closeButton>
             <Modal.Title>Editera produktgrupp</Modal.Title>
          </Modal.Header>

          <Modal.Body>
             <Form id="organisationForm">
                <Form.Group className="mb-3" >
                   <Form.Label>Namn</Form.Label>
                   <Form.Control type="text" name="name" size='lg' defaultValue={object2Edit!=null ? object2Edit.Name : ""}/>
                </Form.Group>               
             </Form>
          </Modal.Body>
          <Modal.Footer>
             <Button variant="primary" onClick={()=>editSaveHandler()}>Spara</Button>
             <Button variant="danger"  onClick={()=>editRemoveHandler()}>Ta bort</Button>
             <Button variant="warning" onClick={()=>setEditShow(!editShow)}>Avbryt</Button>
          </Modal.Footer>
       </Modal>

       <Modal show={addShow} onHide={()=>setAddShow(!addShow)}>
          <Modal.Header closeButton>
             <Modal.Title>Lägg till produktgrupp</Modal.Title>
          </Modal.Header>

          <Modal.Body>
             <Form id="organisationForm">
                <Form.Group className="mb-3" >
                   <Form.Label>Namn</Form.Label>
                   <Form.Control type="text" name="name" size='lg' defaultValue={object2Edit!=null ? object2Edit.Name : ""}/>
                </Form.Group>               
             </Form>
          </Modal.Body>
          <Modal.Footer>
             <Button variant="primary" onClick={()=>addHandler()}>Spara</Button>
             <Button variant="warning" onClick={()=>setAddShow(!addShow)}>Avbryt</Button>
          </Modal.Footer>
       </Modal>          

       <Card>
          <Card.Body>
          <Table responsive="sm">
             <MaterialReactTable 
                  columns={columns} data={props.data} state={state} initialState={props.initialState} muiTablePaperProps={props.muiTablePaperProps} muiTablePaginationProps={props.muiTablePaginationProps} renderTopToolbarCustomActions={() => (<Rubrik>{props.Rubrik}</Rubrik>)}                
                  muiTableBodyRowProps={({ row }) => ({ onClick: (event) => { editOrganisationHandler(event, row); }, sx: { cursor: 'pointer' } })} 
            />
          </Table>
          <Button className="QmeButton" variant="primary" type="submit" onClick={()=>setAddShow(!addShow)}>Ny produktgrupp</Button> 
          </Card.Body>
       </Card>   
       </>
    );
 }