import styled from 'styled-components';

export const ContentWrapper = styled.div`
	width: 100%; /* Full width */
	padding-bottom:20px;
	z-index: 90;
	background-color:#fff;	
    flex: 1;
`;

export const Box = styled.div`
	padding: 20px ;
	width: 100%;
	@media (max-width: 1000px) {
		padding: 70px 30px;
	}
`;

export const Sida = styled.div`
	display_: flex;

	flex-grow-1;
	flex-direction: column;
	justify-content: center;
	font-size: 22px;
	margin: auto ;
	padding-top:20px;
	width: 100%; /* Full width */

	@media (max-width: 991px) {
		padding-top:20px;
	} 

	@media (min-height: 500px) {
		min-height: ${window.innerHeight-340}px;
	} 	
`

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 60px;
`;

export const ContentDiv = styled.div`
	z-index: 97;
`;

export const FooterLink = styled.a`
	color: #fff;
	margin-bottom: 20px;
	font-size: 18px;
	text-decoration: none;

	&:hover {
		color: green;
		transition: 200ms ease-in;
	}
`;

export const Rubrik = styled.p`
	@media (max-width: 991px) {
	 	font-size: 120%;
	 	font-weight: bold;		
	} 
	background-color_: #00c;
	color: #444;
	margin-bottom: 5px;
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 40px;
`;

export const Rubrik_ = styled.p`
	@media (max-width: 991px) {
	 	font-size: 120%;
	 	font-weight: bold;		
	} 

	color: #fff;
	margin-bottom: 5px;
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 60px;
`;

export const Stycke = styled.p`
	font-size: 100%;
	color: #444;
	margin-bottom: 40px;
	// font-family: Arial, Helvetica, sans-serif;
	z-index: 90;
`;

export const StyckeCompact = styled.p`
	font-size: 70%;
	color: #444;
	margin-bottom: 40px;
	// font-family: Arial, Helvetica, sans-serif;
	z-index: 90;
`;

export const Artickel = styled.div`
	width: 100%; /* Full width */
	scroll-margin-top: 80px;
	margin-bottom:40px;
	z-index: 90;
	// flex: 1;
	_background-color:#c00;
	font-size_: 30%;
	padding:0;
`;