import '../App.css';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import React from 'react';
import {useNavigate} from 'react-router';
import {StyckeCompact} from "./ContentStyles";
import Rules from './Rules';

const Prices = () => {
    let navigate = useNavigate();

    function handleSubmitProd(defaultproduct) {
        navigate('/signup?defaultproduct='+defaultproduct);
    }

    return (
    <div>
        <div style={{margin:'-20px'}}>
        <PricingTable highlightColor='#FFC90E'>

            <PricingSlot onClick={() => handleSubmitProd("NS1")} buttonText='Starta din butik' title='Kylskåp 250L' priceText='895:-/månad'>
                <PricingDetail> <b>Bindningstid</b>  36 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b>  0:-</PricingDetail>
                <PricingDetail> <b>Månadskostnad</b> 895:-</PricingDetail>
                <PricingDetail> <b>Antal varor</b> 25</PricingDetail>
                <PricingDetail> <b>Antal produktgrupper</b> 5</PricingDetail>
                <PricingDetail> <b>Uppkoppling</b> WiFi</PricingDetail>
                <PricingDetail> <b>Transaktionskostnad</b> 0.20:-/köp</PricingDetail>
                <PricingDetail> <b>Applikationsexempel</b><br/><small>Tex: Lunch, dryck, snacks mm</small></PricingDetail>
            </PricingSlot>

            <PricingSlot onClick={() => handleSubmitProd("NS2")} buttonText='Starta din butik' title='Butik mini' priceText='295:-/månad'>
                <PricingDetail> <b>Bindningstid</b> 12 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 0:-</PricingDetail>
                <PricingDetail> <b>Månadskostnad</b> 295:-</PricingDetail>
                <PricingDetail> <b>Antal varor</b> 5</PricingDetail>
                <PricingDetail> <b>Antal produktgrupper</b> 1</PricingDetail>
                <PricingDetail> <b>Uppkoppling</b> WiFi</PricingDetail>
                <PricingDetail> <b>Transaktionskostnad</b> 2.50:-/köp</PricingDetail>
                <PricingDetail> <b>Applikationsexempel</b><br/><small>Tex: Honung, äggförsäljning</small></PricingDetail>
            </PricingSlot>

            <PricingSlot onClick={() => handleSubmitProd("NS3")} highlighted buttonText='Starta din butik' title='Butik standard' priceText='1495:-/månad'>
                <PricingDetail> <b>Bindningstid</b> 12 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 0:-</PricingDetail>
                <PricingDetail> <b>Månadskostnad</b> 1495:-</PricingDetail>
                <PricingDetail> <b>Antal varor</b> 50</PricingDetail>
                <PricingDetail> <b>Antal produktgrupper</b> 5</PricingDetail>
                <PricingDetail> <b>Uppkoppling</b> WiFi</PricingDetail>
                <PricingDetail> <b>Transaktionskostnad</b> 0.20:-/köp</PricingDetail>
                <PricingDetail> <b>Applikationsexempel</b><br/><small>Tex: Gårdsbutik, 9-Hålet</small></PricingDetail>
            </PricingSlot>

            <PricingSlot onClick={() => handleSubmitProd("NS4")} buttonText='Starta din butik' title='Butik large' priceText='2495:-/månad'>
                <PricingDetail> <b>Bindningstid</b> 12 månader</PricingDetail>
                <PricingDetail> <b>Startkostnad</b> 0:-</PricingDetail>
                <PricingDetail> <b>Månadskostnad</b> 2495:-</PricingDetail>
                <PricingDetail> <b>Antal varor</b> 200</PricingDetail>
                <PricingDetail> <b>Antal produktgrupper</b> 20</PricingDetail>
                <PricingDetail> <b>Uppkoppling</b> WiFi</PricingDetail>
                <PricingDetail> <b>Transaktionskostnad</b> 0.20:-/köp</PricingDetail>
                <PricingDetail> <b>Applikationsexempel</b><br/><small>Tex: Gårdsbutik, Kiosk, Närbutik</small></PricingDetail>
            </PricingSlot>

        </PricingTable>
        </div>
        <div style={{margin:'10px -20px'}}>
            <StyckeCompact style={{marginLeft:"20px", marginTop:"-10px"}}>
                För att en fysisk butik ska fungera krävs ett elektriskt lås, detta kan en lokal låssmed hjälpa dig med.<br></br>
                Fakturering sker i förväg, 3 månader i taget. Uppsägning av tjänst ska ske innan påbörjan av ny 3-månaders period.<Rules/>
            </StyckeCompact>
        </div>
    </div>
  )
};
export default Prices;