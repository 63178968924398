import { Container, Button,Form, Alert, Modal, Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import { FaFilePdf, FaBars, FaPen, FaBarcode, FaEraser, FaTrash } from 'react-icons/fa';
import React, {useMemo, useState, useEffect } from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import useBackend, {CallAPI, Loading, SpinnerX, Check} from '../services/useBackend';


import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions} from '../services/misc';

export default function MyComponent() {
    const [saving,        setSaving]        = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);
    // const [EAN, setEAN] = useState("");


    const {loading, data, Refresh} =  useBackend('/api/admin/products');
    const {loading:productGroupsLoading, data:ProductGroups} =  useBackend(process.env.PUBLIC_URL+'/api/admin/ProductGroups');
  
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    var pageIndex = parseInt(searchParams.get('pageindex'),10) || 0;
    var pageSize  = parseInt(searchParams.get('pagesize'),10)  || 25;

    const [pagination, setPagination] = useState({ pageIndex: pageIndex, pageSize: pageSize });
    const [editProductData, setEditProductData] = useState(null);
    const [editProductModalshow, setEditProductModalshow] = useState(false);
    const initModal = () => { return setEditProductModalshow(!editProductModalshow) } 

    useEffect(() => { navigate('?pageindex='+pagination.pageIndex+'&pagesize='+pagination.pageSize) }, [pagination]);

    var typingTimeout = null;

    const editProductHandler = (event, rowData)  =>  {
        //navigate("/product/"+rowData.original.Guid);
        setResultVisible(false);
        setEditProductData(rowData.original)
        setEditProductModalshow(!editProductModalshow);        
        //setEAN(rowData.original.EAN);
    }

    const addProductHandler = (event)  =>  {
        event.preventDefault();
        //setEAN("");
        setResultVisible(false);
        setEditProductData({"Guid":"00000000-0000-0000-0000-000000000000", "Name": "", "Description": "", "EAN": "", "Price": 0, "Vat": 25, "State": 1, "AllowManualRegistration": true, "ShowOnPricelist": false, "ProductGroupId": ProductGroups})
        setEditProductModalshow(!editProductModalshow);  
    }

    function handleChange(event){
        if (editProductData.Guid!="00000000-0000-0000-0000-000000000000") {
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(handleSave, 475);
        }
    }

    function getOptions(object){
        return object.map((state) => {
            return <option key={state.Id} value={state.Id}>{state.Name}</option>;
        });
    }  

    async function handleGenerateEAN(){
        setSaving(true);
        let {jsondata} = await CallAPI('/api/admin/GenerateEAN/',"PUT", "{}");
        //editProductData.EAN = jsondata.EAN;
        //setEditProductData(editProductData);
        document.getElementById("EAN").value = jsondata.EAN;
        setSaving(false);
    }

    async function handleRemoveEAN(ProductGuid, EANGuid){
        setResultVisible(false);
        setSaving(true);
        console.log(EANGuid);
        //Product/{ProductGuid}/EAN/Guid
        let {resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/admin/Product/' + ProductGuid + '/EAN/' + EANGuid, "DELETE", "{}");
        Refresh();
        editProductData.ProductVariants = jsondata.ProductVariants;
        setEditProductData(editProductData);         
        setSaving(false);

        console.log("resultVisible: " + resultVisible);

        if (!resultVisible) {
            setResultVisible(false);
        } else {
            setResultVisible(true);
            setResultText(resultText);
        }

    }

    async function handleAddEAN(ProductGuid){
        setResultVisible(false);
        setSaving(true);

        const VariantEAN = document.getElementById('VariantEAN').value;
        const VariantDescription = document.getElementById('VariantDescription').value;
        const formDataObj = {EAN : VariantEAN, Description : VariantDescription};
        let {resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/admin/Product/' + ProductGuid + '/EAN/', 'POST', JSON.stringify(formDataObj));
        Refresh();

        editProductData.ProductVariants = jsondata.ProductVariants;
        setEditProductData(editProductData);     
        
        console.log("resultVisible: " + resultVisible);
        if (!resultVisible) {
            setResultVisible(false);
            document.getElementById('VariantEAN').value="";
            document.getElementById('VariantDescription').value="";    
        } else {            
            setResultVisible(true);
            setResultText(resultText);
        }

        setSaving(false);
    }         
    
    async function handleGeneratePriceTag(ProductGuid) {
        console.log(ProductGuid);
        window.open("/api/Report/pricetag/"+ProductGuid, "_blank")
    }
    
    async function handleSave(){
        setSaving(true);
        setResultText("");
        setResultVisible(false);
        const ProductForm = document.getElementById('ProductForm');
        const formData = new FormData(ProductForm);
    
        const formDataObj = Object.fromEntries(formData.entries());
        formDataObj.ShowOnPricelist = formData.has('ShowOnPricelist') ? true : false;
        formDataObj.AllowManualRegistration =formData.has('AllowManualRegistration') ? true : false;
    
        if (editProductData.Guid==="00000000-0000-0000-0000-000000000000") {
             let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI('/api/admin/products', 'POST', JSON.stringify(formDataObj));
             if (resultVariant!="success") {
                 setResultVariant(resultVariant);   
                 setResultText(resultText);
                 setResultVisible(resultVisible);
             } else {
                 initModal();
             }
        } else {
             let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI('/api/admin/product/'+editProductData.Guid,"PATCH",JSON.stringify(formDataObj));
             if (resultVariant!="success") {
                 setResultVariant(resultVariant);   
                 setResultText(resultText);
                 setResultVisible(resultVisible);
            } 
        }
        Refresh();
        setSaving(false);
    }

    async function handleRemove(){
        setSaving(true);

        let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI('/api/admin/product/'+editProductData.Guid,"DELETE","{}");
        if (errorsException) {
            setResultVariant(resultVariant);   
            setResultText(resultText);
            setResultVisible(resultVisible);
        } else {
            Refresh();
            initModal();
        }
        setSaving(false);
    }    

    const columns = useMemo(() => [
          { size: 40, accessorKey: 'EAN',   header: 'EAN', enableColumnActions: false },
          { size: 40, accessorKey: 'Name',  header: 'Name' },
          { size: 40, accessorKey: 'ProductGroupName',  header: 'Produktgrupp' },
          { size: 20, accessorKey: 'Price', header: 'Pris', enableOrdering: false, enablePinning: false, enableColumnActions: true},          

          { size: 20, header: 'Manuell registrering', enableOrdering: true, enableColumnOrdering: true, enablePinning: true, enableColumnActions: true,
            Cell: ({ row }) => ( <Check checked={row.original.AllowManualRegistration}/> ),
          },

          { size: 20, header: 'Visa på prislista', enableOrdering: true, enableColumnOrdering: true, enablePinning: true, enableColumnActions: true,
            Cell: ({ row }) => (<Check checked={row.original.ShowOnPricelist}/> ),
          },                   
        ]
    );

    const initialState = { density: 'compact', columnVisibility: { 'Manuell registrering': false, 'Visa på prislista':false, 'Price':false }, pagination : pagination };    
    
    const state = { pagination : pagination };       

    if (!loading&&!productGroupsLoading) {
        return (
            <ContentWrapper>

                {editProductData!=null &&
                    <Modal show={editProductModalshow} onHide={initModal}>
                    <Modal.Header closeButton>

                    {editProductData.Guid=="00000000-0000-0000-0000-000000000000" ? (  <Modal.Title>Ny vara</Modal.Title> ) :(<Modal.Title>{editProductData.Name}</Modal.Title>)}
                        
                    </Modal.Header>
                    <Modal.Body>
                    <Form onSubmit={handleSave} id="ProductForm">

                        <Form.Group className="mb-3" >
                            <Form.Label>Produktnamn</Form.Label>
                            <Form.Control type="text" name="Name" placeholder="Produktnamn"  defaultValue={editProductData.Name} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Produktbeskrivning</Form.Label>								
                            <Form.Control type="text" name="Description" placeholder="Produktbeskrivning" defaultValue={editProductData.Description} onChange={handleChange} />
                        </Form.Group>  

                        <Form.Group className="mb-3" >
                            <Form.Label>EAN (Streckkoder)</Form.Label>
                                
                            {editProductData.Guid=="00000000-0000-0000-0000-000000000000" ? (
                                <div className="d-flex">
                                    <Form.Control type="text" id="EAN" name="EAN" placeholder="EAN" defaultValue={editProductData.EAN} onChange={handleChange} />&nbsp;
                                    <Button className="QmeButton" variant="primary" type="button" onClick={handleGenerateEAN} disabled={editProductData.Guid=="00000000-0000-0000-0000-000000000000" ? (false):(true)}>Generera</Button>  
                                </div>

                            ):(
                                <div>
                                    <Table responsive="sm">
                                        <tbody>
                                            {editProductData.ProductVariants.map((state) => {
                                                return <tr key={state.Id}>
                                                    <td>{state.EAN}</td>
                                                    <td>{state.Description}</td>
                                                    
                                                    <td><span className='sub' ProductData={state} onClick={()=>handleRemoveEAN(editProductData.Guid, state.Guid)}><FaTrash /></span></td>
                                                    <td><span className='sub' ProductData={state} onClick={()=>handleGeneratePriceTag(state.Guid)} ><FaBarcode /></span></td>

                                                </tr>;
                                            })}
                                        </tbody>
                                    </Table>

                                    <div className="d-flex">
                                        <Form.Control type="text" id="VariantEAN" name="VariantEAN" placeholder="EAN/Streckkod"  />&nbsp;<Form.Control type="text" id="VariantDescription" name="VariantDescription" placeholder="Färg/Smak/Storlek"  />&nbsp;
                                        <Button className="QmeButton" variant="primary" type="button" onClick={()=>handleAddEAN(editProductData.Guid, )} style={{whiteSpace: "nowrap", minWidth: "auto"}}>Lägg till</Button>  
                                    </div>
                                </div>
                            )}

                        </Form.Group>                         

                        <Form.Group className="mb-3" >
                            <Form.Label>Produktgrupp</Form.Label>
                            <Form.Select name="ProductGroupId" defaultValue={editProductData.Guid=="00000000-0000-0000-0000-000000000000" ? (ProductGroups.ProductGroups.find((element) => element.Default == true).Id):(editProductData.ProductGroupId)} onChange={handleChange}>
                
                                {getOptions(ProductGroups.ProductGroups)}
                            </Form.Select>
                        </Form.Group>  															

                        <Form.Group className="mb-3" >
                            <Form.Label>Pris inkl. moms</Form.Label>
                            <Form.Control type="text" name="Price" placeholder="Produktpris" defaultValue={editProductData.Price} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Momssats</Form.Label>
                            <Form.Select name="Vat" defaultValue={editProductData.Vat} onChange={handleChange}>
                                <option value="6">6% Tidningar, böcker, taxi, buss, flyg, tåg i Sverige</option>
                                <option value="12">12%  Livsmedel, restaurangbesök, hotell, konstverk</option>
                                <option value="25">25% Standard</option>
                            </Form.Select>
                        </Form.Group>  							

                        <Form.Group className="mb-3" >
                            <Form.Check 
                                type="switch"
                                name="ShowOnPricelist"
                                id="ShowOnPricelist"
                                label="Visa på prislista"
                                defaultChecked={editProductData.ShowOnPricelist}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Check 
                                type="switch"
                                name="AllowManualRegistration"
                                id="AllowManualRegistration"
                                label="Tillåt manuell registrering av varan i app"
                                defaultChecked={editProductData.AllowManualRegistration}
                                onChange={handleChange}
                            />
                        </Form.Group>  	

                        <Form.Group className="mb-3"  hidden={!saving}>
                            <SpinnerX  />
                        </Form.Group>                                      
                        
                        <Form.Group className="mb-3"  hidden={!resultVisible}>
                            <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                        </Form.Group> 												 													
                    </Form>                   

                    
                    </Modal.Body>
                    <Modal.Footer>
                        {editProductData.Guid=="00000000-0000-0000-0000-000000000000" ? (  
                            <>
                                <Button className="QmeButton" variant="primary" type="button" onClick={handleSave}>Lägg till varan</Button>    
                                <Button variant="danger" onClick={initModal}>Avbryt</Button>
                            </>
                        ) : (
                            <>
                                <Button variant="danger" type="button" onClick={handleRemove}>Ta bort varan</Button>    
                                <Button variant="secondary" onClick={initModal}>Stäng</Button>
                            </>
                        )}
                        
                        
                    </Modal.Footer>
                    </Modal>
                }

                <Container>
                    <Sida>                    
                        <MaterialReactTable  columns={columns} data={data.Products}  onPaginationChange={setPagination} state={state} initialState={initialState}
                        muiTablePaginationProps={{ showFirstButton: false, showLastButton: false ,labelRowsPerPage:"Rader"}}
                        renderTopToolbarCustomActions={() => (<Rubrik>Produkter</Rubrik>)}
                        
                            muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {editProductHandler(event, row);},sx: {cursor: 'pointer'},})}
                            />                         
                        <Button className="QmeButton" variant="primary" type="submit" onClick={addProductHandler}>Ny vara</Button>               
                    </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (<Loading />);        
    }
}