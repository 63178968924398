import Faq from "react-faq-component";
import React from 'react';
import $ from "jquery";
import useBackend, {Loading, SpinnerX} from '../services/useBackend';

const config = {
  animate: true,
  expandIcon: "+",
  collapseIcon: "X",
};

const styles = {
  arrowColor: "#FFC90E",
  rowContentColor: '#333',  // Färg svarstext
  titleTextColor: '#333',
  titleTextSize: '40px',
  transitionDuration:'0.5s'
};
  
export default function FAQ() {

  const {loading, data, Refresh} =  useBackend('/backend/Faqs?FilterGroup=NS');

    var faqData = {title: "Frågor och svar!", rows: []};

    $.each(data, function(id,obj) {
      faqData.rows.push({title: obj.Title, content: obj.Answer });
    });

    return (<><Faq data={faqData} styles={styles} config={config} /></>);
}