import { Container, Button,Form, Alert, Modal, Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import { FaFilePdf, FaBars, FaPen } from 'react-icons/fa';
import React, {useMemo, useState, useEffect } from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import useBackend, {CallAPI, Loading, SpinnerX, Check} from '../services/useBackend';

import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions} from '../services/misc';

export default function Instances() {
    const [saving,        setSaving]        = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);
    const [EAN, setEAN] = useState("");

    const {loading, data, resultSuccess, Refresh} =  useBackend('/api/admin/instances');
    // const {loading:productGroupsLoading, data:ProductGroups} =  useBackend(process.env.PUBLIC_URL+'/api/admin/ProductGroups');
  
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    var pageIndex = parseInt(searchParams.get('pageindex'),10) || 0;
    var pageSize  = parseInt(searchParams.get('pagesize'),10)  || 25;

    const [pagination, setPagination] = useState({ pageIndex: pageIndex, pageSize: pageSize });

    const [instanceDetailsData, setInstanceDetailsData] = useState(null);    
    const [instanceDetailsModalshow, setInstanceDetailsModalshow] = useState(false);
    const [instanceShopsModalshow, setInstanceShopsModalshow] = useState(false);
    const initModal = () => { return setInstanceDetailsModalshow(!instanceDetailsModalshow) } 
    const initShopsModal = () => { return setInstanceShopsModalshow(!instanceShopsModalshow) } 

    // Hålla adressfältet uppdaterat
    useEffect(() => { navigate('?pageindex='+pagination.pageIndex+'&pagesize='+pagination.pageSize) }, [pagination]);

    var typingTimeout = null;

    const showInstanceDetailsHandler = (event, rowData)  =>  {        
        setResultVisible(false);
        setInstanceDetailsData(rowData.original)
        setInstanceDetailsModalshow(!instanceDetailsModalshow); 
        console.log(rowData.original);
    }

    function handleChange(event){
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(handleSave, 475);
    }

    function getOptions(object){
        return object.map((state) => {
            return <option key={state.Id} value={state.Id}>{state.Name}</option>;
        });
    }
    
    async function handleSave(){
        setSaving(true);
        setResultText("");
        setResultVisible(false);
        // const ProductForm = document.getElementById('ProductForm');
        // const formData = new FormData(ProductForm);
    
        // const formDataObj = Object.fromEntries(formData.entries());
        // formDataObj.ShowOnPricelist = formData.has('ShowOnPricelist') ? true : false;
        // formDataObj.AllowManualRegistration =formData.has('AllowManualRegistration') ? true : false;
    
        // if (editProductData.Guid==="00000000-0000-0000-0000-000000000000") {
        //      let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI('/api/admin/products', 'POST', JSON.stringify(formDataObj));
        //      if (resultVariant!="success") {
        //          setResultVariant(resultVariant);   
        //          setResultText(resultText);
        //          setResultVisible(resultVisible);
        //      } else {
        //          initModal();
        //      }
        // } else {
        //      let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI('/api/admin/product/'+editProductData.Guid,"PATCH",JSON.stringify(formDataObj));
        //      if (resultVariant!="success") {
        //          setResultVariant(resultVariant);   
        //          setResultText(resultText);
        //          setResultVisible(resultVisible);
        //     } 
        // }
        Refresh();
        setSaving(false);
    }

    const columns = useMemo(() => [
          { size: 40, accessorKey: 'Company',   header: 'Företag', enableColumnActions: false },
          { size: 40, accessorKey: 'eMail',  header: 'Epost' },
          { size: 20, accessorKey: 'SwishIdentifier', header: 'SwishIdentifier', enableOrdering: false, enablePinning: false, enableColumnActions: true},                          
        ]
    );

    const initialState = { density: 'compact', columnVisibility: { 'Manuell registrering': false, 'Visa på prislista':false, 'Price':false }, pagination : pagination };    
    
    const state = { pagination : pagination };       

    if (!loading) {
        return (
            <ContentWrapper>
                {instanceDetailsData!=null &&
                    <>
                    <Modal show={instanceDetailsModalshow} onHide={initModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{instanceDetailsData.Company}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSave} id="ProductForm">

                                <Form.Group className="mb-3" >
                                    <Form.Label>Företag</Form.Label>
                                    <Form.Control type="text" name="Company" placeholder="Företag"  defaultValue={instanceDetailsData.Company} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>Gatuadress</Form.Label>
                                    <Form.Control type="text" name="Street" placeholder="Gatuadress"  defaultValue={instanceDetailsData.Street} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>Postnummer</Form.Label>
                                    <Form.Control type="text" name="Zip" placeholder="Postnummer"  defaultValue={instanceDetailsData.Zip} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>Ort</Form.Label>
                                    <Form.Control type="text" name="City" placeholder="Ort"  defaultValue={instanceDetailsData.City} onChange={handleChange} />
                                </Form.Group>                                                                                                

                                <Form.Group className="mb-3" >
                                    <Form.Label>Orgnr</Form.Label>
                                    <Form.Control type="text" name="VATIdentification" placeholder="Orgnr"  defaultValue={instanceDetailsData.VATIdentification} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>eMail</Form.Label>
                                    <Form.Control type="text" name="eMail" placeholder="eMail"  defaultValue={instanceDetailsData.eMail} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>Telefon</Form.Label>
                                    <Form.Control type="text" name="Phone" placeholder="Telefon"  defaultValue={instanceDetailsData.Phone} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>Swishnummer</Form.Label>
                                    <Form.Control type="text" name="SwishIdentifier" placeholder="Swishnummer"  defaultValue={instanceDetailsData.SwishIdentifier} onChange={handleChange} />
                                </Form.Group>                                    	                                                                 	                                  							

                                <Form.Group className="mb-3"  hidden={!saving}>
                                    <SpinnerX  />
                                </Form.Group>                                      

                                <Form.Group className="mb-3"  hidden={!resultVisible}>
                                    <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                                </Form.Group> 												 													
                            </Form>                                           
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="QmeButton" variant="primary" type="button" onClick={initShopsModal}>Butiker</Button>
                            <Button variant="secondary" onClick={initModal}>Stäng</Button>                                                
                        </Modal.Footer>
                    </Modal>

                    <Modal show={instanceShopsModalshow} onHide={initShopsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Butiker</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            instanceDetailsData.Shops.map((shop) => {
                            return <div>{shop.Name} {shop.EntryCode}</div>;
                        })                                            
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={initShopsModal}>Stäng</Button>                                                
                    </Modal.Footer>
                    </Modal>   
                    </>                 
                }

                <Container>
                    <Sida>                    
                        <MaterialReactTable  columns={columns} data={data.Instances}  onPaginationChange={setPagination} state={state} initialState={initialState}
                            muiTablePaginationProps={{ showFirstButton: false, showLastButton: false ,labelRowsPerPage:"Rader"}}
                            renderTopToolbarCustomActions={() => (<Rubrik>Instanser</Rubrik>)}
                        
                            muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {showInstanceDetailsHandler(event, row);},sx: {cursor: 'pointer'},}
                            )}
                        />                         
                    </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        if (resultSuccess) {
            return (<div>Kunde inte ladda data</div>);        
        } else {
            return (<Loading />);        
        }
    }
}